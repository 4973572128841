
html.nav-open body {
    overflow: hidden;
}

@media only screen and (max-width: 1024px) {
    .nav-open .nav-sections {
        width: 100vw;
        z-index: 999;
        top: 0;
    }

    .linha-menu {
        top: 67px;
    }

    header .nav-sections-item-title{
        display: none;
    }
    
    .nav-toggle {
        z-index: 999;
    }
    .nav-toggle:after {
        content: none !important;
    }


    .i-up:after {
        content: '\e621' !important;
    }

    .i-close:before {
        content: '\e616' !important;
        font-size: 20px;
        top: 4px;
        left: 4px;
        position: relative; 
    }

    #megamenu {

        .nav-toggle:before {
            content: '\e616' !important;
            font-size: 20px;
            top: 4px;
            left: 4px;
            position: relative;        
        }

        .i-up:after {
            content: '\e621' !important;
        }
    
        .i-close:before {
            content: '\e616' !important;
            font-size: 20px;
            top: 4px;
            left: 4px;
            position: relative; 
        }

        .topmenu {
            .category-item .ui-menu-icon {
                width: 55px;
                height: 35px;
                display: block;
                position: absolute;
                top: 2px;
                right: -47px;
                z-index: 999;
            }

            .nav-open .nav-toggle:after {
                content: none !important;
            }

            .nav-toggle {
                z-index: 999999;
            }

            .parent .level-top:after {
                right: -30px !important;
            }

            .category-item a {
                padding: 8px 0px 8px 15px !important;
                margin-right: 50px !important;
            }
        }
    
        .nav-sections {
            width: calc(30% - 54px);
        }
    }
}

@media only screen and (max-width: 767px){
    #megamenu {
        .topmenu .parent .level-top:after {
            right: -30px !important;
        }
        
    }
    header .nav-sections-item-content {
        margin-top: 64px !important;
    }
}