// ------------------------------------------------------------------------------
// VARIABLES
// ------------------------------------------------------------------------------

// Typography

$font-format: ttf otf !default;

$rem-baseline: 10px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

$font-size-40: 40px !default;
$font-size-30: 24px !default;
$font-size-26: 26px !default;
$font-size-24: 24px !default;
$font-size-20: 20px !default;
$font-size-22: 22px !default;
$font-size-18: 18px !default;
$font-size-16: 16px !default;
$font-size-14: 14px !default;
$font-size-12: 12px !default;
$font-size-10: 10px !default;
$font-size-8: 8px !default;

// Colors

$black: #171717;
$black-80: #4d4d4d;
$black-70: #707070;
$black-60: #A2A2A2;
$white: #fff;
$gray: #565656;
$gray-ice: #c4c4c4;
$green: #0f9d58;
$red: #db4437;
$blue: #4285f4;
$light-ice: #f5f5f5;
$light-gray: #eee;
$light-green: #d8e7e0;
$light-red: #eddedc;
$light-blue: #c7dbcf;

// Icon Font Codes
$icons: (
  whatsapp: '\f232',
  copy: '\f0c5',
  up: '\f077',
  down: '\f107',
  shopping-basket: '\f291',
  plus: '\f067',
  minus: '\f068',
  delete: '\f2ed',
  angle-left: '\f104',
  close: '\f00d',
  truck: '\f0d1',
  cart: '\f09d'
);
