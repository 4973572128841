.catalog-product-view {
    @import '../componentes/swatch-opt';

    .product-info-main {
        margin: 0 auto;
        max-width: 500px;
        .page-title {
            @extend .f18;
            text-transform: uppercase;
        }

        .oldprice {
            padding: 0px;
        }

        .box-tocart {
            .action.tocart {
                @include botao-padrao($black, $white, true);
                width: calc(100%);
                float: left;
                position: relative;
                z-index: 1;
            }
            .label {
                @extend .f10;
                float: left;
                margin-right: 10px;
                position: relative;
                top: 13px;
            }

            #qty {
                padding: 0;
                
                border: 1px solid $black-70;
            }
            .qty .label {
                text-transform: uppercase;
            }
        }

        .product-info-price {
            border: 0;
            height: 50px;
            display: inline-block;
            .widget {
                width: 130px;
                float: right;
                position: relative;
                top: 6px;
            }
        }

        .product-social-links .action.mailto.friend.amconf-share-link {
            margin: 0;
        }

        .product-social-links {
            @extend .f12;
            color: $black;
            letter-spacing: 2px;
            position: relative;
        }

        .product-addto-links {
            display: none;
        }
        
        .product-social-links .action.mailto.friend.amconf-share-link {
            float: right;
        }

        .product.attribute.sku {
            margin-top: -22px;

            .type, .value {
                margin-top: 8px;
                @extend .f10;
                color: rgba(0, 0, 0, 0.4);
                text-transform: uppercase;
            }
        }
        
        .product-info-price {
            .price-box {
                .old-price {                    
                    .price {
                        position: relative;
                        top: 5px;
                        margin-left: -20px;
                        left: 28px;
                        @extend .f12;
                    }
                }
                .price {
                    @extend .f12;
                    margin: 0 auto;
                }
                
                margin-left: -5px;
                margin-top: 8px;
            }
        }
    }

    .normal-price {
        margin: 0 auto;
        order: 0;
    }

    .eth_installments {
        position: relative;
        top: 32px;
        @extend .f18;
    }

    .product-options-wrapper {
        .fieldset {
            margin-top: 0;
        }
    }

    
    .product.info.detailed {
        .product.data.items>.item.content {
            border: 0;
            @extend .f12;
            padding: 0;
        }

        .mgz-element-column > .mgz-element-inner {
            padding: 0;
        }

        .product.data.items>.item.title>.switch {
            @extend .f12;
            padding: 0;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            margin-bottom: 16px;
            height: 35px;
            text-transform: uppercase;
            color: $black;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
        .amtabs-accordion-view.product.data.items {
            border: 0;
        }
    }

    .product-info-main .product-add-form {
        padding-top: 0px;
    }

    @media screen and (max-width:1023px){
        .amrelated-grid-wrapper .widget-product-grid {
            display: block;
        }

        .catalog-product-view .product.media {
            margin-top: -50px;
        }

        .product-social-links .action.towishlist span {
            font-size: 12px;
        }
        
        .product-info-main .product-social-links .action.mailto.friend.amconf-share-link {
            font-size: 12px;
            float: none !important;
            margin-top: 12px !important;
        }
        .product.data.items {
            margin: 0;
        }
        .product-info-main .page-title {
            margin-top: 36px;
        }
    }
    .product-info-main .product.attribute.overview {
        display: none;
    }
}