li {
	list-style: none;
}
.sales-order-view {
	font-weight: normal !important;
	.timeline {
		padding-top: 10px;
		.ico {
			width: 64px;
			height: 64px;
			background-color: #fff;
			border-radius: 50%;
			display: block;
			margin: 10px auto;
			border: 3px solid #fff;
			z-index: 8;
			position: relative;
		}
		.ico.active {
			border: 3px solid #0E9E58;
		}
		i {
			@extend .f24;
			line-height: 64px;
		}
		h3 {
			@extend .f20;
			letter-spacing: 2px;
			margin-bottom: 28px;
			text-align: center;
		}
		hr {
			margin: 26px 0;
		}
		.line-back {
			position: relative;
			top: -118px;
			border-top: 2px solid #fff;
			max-width: 300px;
			margin: 0 auto;
		}
		ul {
			display: table;
			margin: 0 auto;
	
			li {
				float: left;
				width: 88px;
				text-align: center;
				background-color: #eee;
				margin: 0 18px;
	
				strong {
					@extend .f12;
				}
	
				p {
					@extend .f12;
					margin: 0;
				}
	
				small {
					@extend .f12;
				}
			}
		}
	}
	
	address {
		line-height: 24px;
	}
	.sidebar {
		display: none;
	}
	.order-items {
		position: relative;
	}
	.account .messages {
		margin-bottom: 0;
	}
	.account .column.main .block.block-order-details-view .block-content:not(.widget) .box, [class^='sales-guest-'] .column.main .block.block-order-details-view .block-content:not(.widget) .box, .sales-guest-view .column.main .block.block-order-details-view .block-content:not(.widget) .box {
		width: 100%;
	}
	
	.page-title-wrapper {
		height: 30px;
		margin-left: 30px;
		margin-bottom: 30px;
	}
	
	.order-actions-toolbar {
		position: absolute;
		top: 43px;
		right: 30px;
	}
	.pedido-items {
		padding: 0px;
	}
	.order-details-items .slick-dots {
		margin-left: 50%;
		transform: translateX(-50%);
		width: auto;
	}
	.sales-order-view {
		max-width: 800px;
		margin: 0 auto;
	}
	#maincontent {
		list-style: none;
		background-color: #eee;
		padding: 16px;
		margin: 12px auto;
		border-radius: 4px;
	}
	.print span {
		@extend .f12;
		color: #333;
	}
	.order-details-items {
		border: 0;
		padding: 0;
	}
	.inner_buttons button {
		margin-top: 30px !important;
		@include botao-padrao($black, $white, true);
		i {
			padding: 0 8px 0 0;
		}
	}
	.w-50 {
		width: 50%;
		float: left;
	}
	.box .box-title {
		display: block !important;
		color: #171717;
	}
	.box .box-title span {
		font-size: 14px !important;
	}
	.box-order-billing-address, .box-order-shipping-address, .box-order-shipping-method, .box-order-billing-method {
		width: 100% !important;
		padding: 8px;
		box-sizing: content-box;
		float: none !important;
	}
	.box-order-billing-address .box-content, .box-order-shipping-address .box-content, .box-order-shipping-method .box-content, .box-order-billing-method .box-content {
		border: 1px dashed #999;
		padding: 12px 8px 8px 8px;
		min-height: 40px;
		@extend .f14;
	}
	.page-title {
		margin-top: 12px;
		@extend .f30;
	}
	.order-links {
		display: none;
	}
	.clearboth {
		clear: both;
	}
	hr {
		margin: 10px 0;
	}
	.rastreio {
		font-size: 23px;
		letter-spacing: 4px;
		margin-bottom: 28px;
		text-align: center;
		padding-top: 28px;
	}
	.rastreio strong {
		text-decoration: underline;
		position: relative;
		top: 1px;
		letter-spacing: 2px;
	}
	.order-items h3 {
		text-align: center;
		@extend .f18;
		margin: 14px 0 32px 0;
	}
	.pedido-items, .order-items {
		margin-bottom: 0px;
	}
	.pedido-items  {
		.info-product {
			width: 250px;
			height: 110px;
		}
		.info-product img {
			width: 100px;
			height: 100px;
		}
		.details-product {
			width: 140px;
			float: right;
			padding: 0 8px;
			box-sizing: border-box;
		}
		li {
			float: left;
	
			p:first-child {
				font-weight: bold;
				@extend .f12;
			}
			p {
				@extend .f10;
			}
			strong {
				margin-right: 5px;
			}
		}
	}
	
	.grand_total {
	}
	
	.image-product {
		float: left;
	}
	
	.block-order-details-view address {
		font-size: 12px;
	}
	
	.table-order-items {
		width: 50%;
		float: left;
		tfoot {
			display: block;
			background: none;
		}
	}
	
	.table tfoot>tr:first-child th, .table tfoot>tr:first-child td {
		padding: 10px;
	}
	.account .table-wrapper {
		border-bottom: 1px solid #C4C4C4;
		padding-bottom: 18px;
	}
	
	.order-details-items tfoot td {
		background: none;
	}
	.inner_buttons {
		float: right;
		text-align: right;
		
		button {
			margin-top: 20px;
			padding: 8px 12px;
		}
	}
	
	.e-close {
		@include botao-padrao($black, $white, true);
		text-align: center;
		text-decoration: none;
	}
	
	.e-close:hover {
		text-decoration: none;
		color: #fff;
	}
	
	.mobon {
		display: none;
	}
	.timeline ul {
		padding: 0;
	}
	.order-details-items {
		margin-bottom: -30px;
	}

	@media only screen and (max-width: 1024px) {
		#maincontent {
			margin: 12px 0;
		}
		
		.rastreio {
			padding-top: 0px;
		} 
		.order-details-items .slick-dots {
			margin-left: 0;
			transform: 0 !important;
		}
		.timeline .line-back {
			display: none;
	   }
		.timeline ul li {
			float: none;
			width: 100% !important;
			margin: 0 !important;
	   }
		.rastreio {
			font-size: 16px;
			margin-top: 54px;
	   }
		.e-close {
			width: 100%;
	   }
		.mobon {
			display: block;
	   }
		.page-title-wrapper {
			height: 52px;
			text-align: center;
	   }
		html, body {
			margin: 0;
			height: 100%;
			overflow-x: hidden 
	   }
	   .order-status {
		   position: relative;
		   top: 12px;
	   }
		.w-50 {
			width: 100% !important;
	   }
		.inner_buttons, .table-order-items {
			width: 100%;
			text-align: center;
	   }
		.table-order-items {
			margin-top: 8px;
	   }
		.table-order-items>tfoot>tr {
			width: 100%;
	   }
		.table-order-items>tfoot>tr>th, .table-order-items>tfoot>tr>td {
			display: inline-grid;
	   }
		.order-title {
			display: none;
	   }
		.order-details-items .table-order-items tbody+tbody {
			border: 0;
	   }
		.table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
			display: inline-block;
	   }
	}
}