.eth-video {
    .eth_play{
        display: inline-block;
        border-radius: 150%;
        padding-top:10px;
    }
    
    .video-gallery{
        cursor:pointer;
    }
}

.page-layout-1column {
    .eth-video {
        text-align: center;
        margin-bottom: 10px;
        float: left;
        width: 130px;
        height: 100px;
        line-height: 170px;
        height: 130px;
    }
}

.page-layout-modelo-full {
    .eth-video {
        text-align: center;
        width: 100%;
    }
}