.minicart-wrapper {
  .swatch-option.text {
    background: 0 0;
    color: inherit;
    padding: 0;
    border: none;
    font-size: 12px;
    margin-top: -3px;
  }

  .codeseller-box {
    border-bottom: 1px solid $gray-ice;
  }

  .minicart-items-wrapper {
    border: 0;
    margin: 0;
    overflow: hidden;
    height: auto !important;
  }

  .block-minicart .subtitle { 
    display: none !important;
  }
  @media only screen and (min-width: 1024px) {
    .copy-cart {
      padding-right: 17px;
    }
  }

  .copy-cart {
    position: fixed;
    bottom: 71px;
    text-align: center;
    right: 76px !important;
    z-index: 999;
    cursor: pointer;
    
    i {
      @extend .f14;
      margin-right: 5px;
      position: relative;
      top: 1px;
    }
  }

  .amlabel-position-wrapper{ 
    z-index: 1 !important
  }

  .ui-dialog {
    z-index: 200;
    position: fixed;
    top: 0;
    right: 0;

    .block-minicart {
      height: 100vh;
      max-width: 340px;
      margin-top: 0;
      padding: 0;
      border: 0;
      right: 0;
      overflow: auto;
      
      background: $white;
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        height: 30px;
      }
  
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
  
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
  
      overflow: auto;
      margin: 0;
      border: 0;
      background-color: $white;
    }
  }
  
  .minicart-wrapper.active {
    z-index: 999999999;
  }
  
  .cart-header {
    padding: 0 20px;
    background-color: $black;
    height: 45px;
    line-height: 45px;
    color: $white;
    
    p {
      @extend .f16;
      @include icon(before, shopping-basket, false, 8px, 'right');
    }
  }

  .cart-content {
    .total {
      background-color: $white;
      @extend .f14;
      padding: 0 17px;
      
      span {
        position: relative;
        top: -2px;
        float: right;
        @extend .f20;
        color: $black-80;
      }

      @include clearfix();
    }

    .no-items {
      padding: 0 17px;
      position: relative !important;
      text-align: center;
      margin-top: 32px;
      margin-top: 18px;
      span {
        @extend .f16;
      }
  
      p {
        @extend .f14;
      }
      
      button {
        margin-top: 32px;
        font-weight: normal;
        @include botao-padrao($black, $white, true);
      }
    }
  }

  #top-cart-btn-checkout {
    @include botao-padrao($black, $white, true, 45px);
    text-align: center;
    @extend .f14;
    margin-top: 8px;
  }

  .compartilhar-skus {
    display: none;
  }

  body {
    background-color: red;
  }

  .viewcart {
    margin-top: 20px;
    display: block;
    @extend .f12;
    color: $black;
  }
  
  .product-item-details {
    position: relative;
    padding-left: 70px;
    margin-left: 12px;

    dl {
      margin: 0;
    }
    
    dd {
      margin-bottom: 0;
    }

    @include clearfix;

    .product-item-name {
      padding-right: 44px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      a {
        @extend .f12;
        color: $black;        
      }
    }
    .product-options {
      .label, .values {
        float: left;
      }
      .label {
        margin-right: 4px;
      }
      .values {
        margin-right: 8px;
      }
    }
  }

  .product-options .content{
    text-align: left;
  }

  .update-cart-item {
    float: none !important;
  }

  #mini-cart {
    .actions {
      position: absolute !important;
      top: 0px !important;
      margin: 0 !important;
      right: 0 !important;

      .primary {
        display: none
      }

      .delete:before {
        color: $black;
      }
    }
    .item {
      border-bottom: 1px solid $gray-ice;
      position: relative;
    }
  }

  .price-wrapper {
    display: inline-block;
  }

  dl.product.options.list {
    color: $black;
  }

  .contador-de-quantidade {
    float: left;
  }

  .price-container {
    float: right;
    color: $black;
  }

  .product-item:not(:first-child) {
    border: 0;
  }

  #btn-minicart-close {
    margin-top: 12px;
    margin-right: 2px;
    color: $white;
    &:before {
      font-size: 20px;
      color: #fff;
    }
  }

  .clearfix {
    @include clearfix();
  }
}

.block-search {
  z-index: 88 !important;
}

@media only screen and (max-width: 1024px) {
  .minicart-wrapper .block-minicart {
      width: 90vw;
  }
}