.contador-de-quantidade {
  position: relative;
  left: -4px;
  button {
    @include botao-padrao($black, $white, false);
    padding: 0;
    line-height: 26px;
    height: 27px;
    text-align: center;
    width: 25px;

    &:before {
      font-size: 9px;
      position: relative;
      top: -1px;
    }
  }

  .sub {
    position: relative;
    left: 4px;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    @include icon($position: before, $icon: minus);
  }

  .add {
    position: relative;
    right: 3px;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    @include icon($position: before, $icon: plus);
  }

  input {
    @extend .f14;
    width: 38px !important;
    height: 27px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  }
}