@media screen and (min-width:1023px){
    .page-layout-modelo-full {
        .product-info-main {
            width: 40%;
            position: sticky;
            top: 20px;
        }

        .product.media {
            width: 57%;
        }

        .amasty-gallery-image {
            width: auto !important;
        }
        
        #amasty-main-container {
            margin: 0 auto;
            display: none;
        }

        #amasty-gallery-container {
            width: 100% !important;
            position: relative !important;
        }
    }
}