#block-discount{
  button{
    @include botao-padrao($black, $white, true, 45px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    line-height: 0;
    @extend .f14;
    width: 75px;
    font-weight: normal;
  }

  input[type="text"]{
    @include input-padrao($white, $black-80, false, 75px);
    float: left;
  }

  button{
    @include botao-padrao($black, $white, true, 45px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    line-height: 0;
    @extend .f14;
    width: 75px;
    font-weight: normal;
  }

  .content{
    padding-bottom: 1px !important;
  }
}