.checkout-index-index {

    @import '../uikit/radio-padrao';

    background: #E5E5E5;

    .table-checkout-shipping-method {
        min-width: 100%;
        tbody td {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    .opc-progress-bar {
        text-align: center;
    }

    .opc-progress-bar-item:before {
        background: black;
        border-color: black;
        border: 1px solid #000;
        height: 1px;
        top: 38px;
        left: 75px;
    }

    .opc-wrapper .step-title {
        border: 0;
    }

    #onepage-checkout-shipping-method-additional-load {
        display: none;
    }

    #shipping {
        background-color: #FFF;
        border-radius: 8px;
        margin-bottom: 24px !important;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        position: relative;
        padding: 16px;
        top: 5px;
    }

    #opc-shipping_method {
        background-color: #FFF;
        border-radius: 8px;
        margin-bottom: 24px !important;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        position: relative;
        padding: 16px;
    }

    .discount-code {
        background-color: #FFF;
        border-radius: 8px;
        margin-bottom: 24px !important;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        position: relative;
        padding: 16px;
        color: $black;
    }

    .amcard-field-container {
        background-color: #FFF;
        border-radius: 8px;
        margin-bottom: 24px !important;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        position: relative;
        padding: 16px;
        top: 5px;
    }

    .checkout-payment-method .payment-option-title .action-toggle {
        color: $black;
    }

    .amcard-field-container {
        max-width: none;
    }

    .payment-option-title {
        border: 0;
        max-width: none;
    }

    .opc-payment {
        background-color: #FFF;
        border-radius: 8px;
        margin-bottom: 24px !important;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        position: relative;
        padding: 16px;
        top: -20px;

        button[type="submit"]{
            margin-top: 5px;
            @extend .f14;
            @include botao-padrao($green, $white, false);
        }
    }

    #opc-shipping_method {
        button[type="submit"]{
            margin-top: 5px;
            @extend .f14;
            @include botao-padrao($green, $white, false);
        }
    }
    
    #moipcc-form {
        select {
            @extend .f14;    
        }
    }

    .shipping-information-content {
        @extend .f14;
        color: $black-80;
    }

    #payment_form_moipboleto .payment-method-wirecard-label, #payment_form_moipcc .payment-method-wirecard-label {
        display: inline-block;
    }

    .field-to-pay  {

        input::-webkit-input-placeholder {
            color: #eee !important;
        }
        input[type="number"] {
            @include input-padrao($light-gray, $black-80, true);
        }

        input[type="text"] {
            &::placeholder {
                color: $light-ice;
            }
            @include input-padrao($light-gray, $black-80, true);
        }

        select {
            @include select-padrao($light-gray, $black-80);
            color: #808080;
        }
    }

    #payment_form_cc_moipcc {
        .legend-title {
            @extend .f14;
        }

        .credit-card-types img {
            width: 30px;
            position: relative;
            top: 3px;
        }
    }

    #payment_form_moipboleto {
        color: $black-80;
    }
    

    .action.primary:hover {
        background: $black;
        border: 0;
        color: $white;
    }

    #payment_form_moipcc {
        #payment_form_cc_moipcc {
            border-color: #eee;
        }

        .legend {
            background-color: #eee;
            border: 0;
        }

        .actions-toolbar {
            button {
                width: 100%;

                &:hover {
                    background: $black;
                }
            }
        }

    }

    .payment-method, .table-totals, .checkout-billing-address {
        select {
            option {
                @extend .f16;
            }
        }
        @extend .f16;

        select {
            @include select-padrao($light-gray, $black-80);
        }
        
        .payment-method-wirecard-label-icon {
            position: relative;
            top: 5px;
        }
    }

    .opc-progress-bar-item:before {
        background: none;
        border-color: transparent;
    }
    .opc-progress-bar-item:last-child:before {
        left: -81px;
    }

    .opc-progress-bar-item_active:last-child ~ .e-accordion {
        display: none;
    }

    .opc-progress-bar-item>span:before, .opc-progress-bar-item._active:before {
        background: $black;
        border-color: $black;
    }

    .opc-progress-bar-item._active>span:after {
        border-color: $black;
    }

    .opc-progress-bar-item>span:before, .opc-progress-bar-item>span:after {
        height: 61px;
        width: 61px;
        margin-left: -35px;
        top: 6px;
        border: 2px solid #ccc;
    }

    .opc-progress-bar-item>span:after, .opc-progress-bar-item>span:after {
        background-color: #E5E5E5;
        border-color: $black;
    }

    .opc-progress-bar-item>span:after {
        border-color: $black;
        line-height: 60px;
        font-size: 18px !important;
    }

    .opc-progress-bar-item>span {
        @extend .f12;
        padding: 0 !important;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    .opc-progress-bar-item {
        margin-left: 18px;
        padding-top: 85px;
    }

    .opc-progress-bar .opc-progress-bar-item:nth-child(1)>span {
        @include icon(after, truck, 16px);
    }

    .opc-progress-bar .opc-progress-bar-item:nth-child(2)>span {
        @include icon(after, cart, 16px);
    }

    .opc-progress-bar .opc-progress-bar-item._active {
        opacity: 1;
    } 

    .opc-progress-bar .opc-progress-bar-item {
        opacity: 0.5;
    }

    .opc-wrapper .shipping-address-item {
        width: calc(50% - 32px);
        border: 1px dashed #999;
        padding: 12px 8px 8px 8px;
        font-size: 14px;
        margin-left: 16px;
        margin-top: 32px;
    }

    .checkout-shipping-address {
        button {
            @extend .f14;
            @include botao-padrao($black, $white, false);
        }
    }

    .ship-via {
        color: $black-80;
    }


    .opc-sidebar {
        margin: 0;
        position: relative;
        top: -12px;
        .opc-block-summary, .ship-to, .ship-via {
            margin: 16px 0;
            -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
            -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
            box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
            background-color: $white;
            border-radius: 8px;
            padding: 22px 30px;
        }

        .opc-block-shipping-information {
            padding: 0;
        }

        .minicart-items-wrapper {
            max-height: none;
        }

        .product-item-name {
        }

        .details-qty {
            .label {
            }
        }
    }

    #checkout-step-shipping .action-show-popup {
        margin-left: 16px;
    }

    .opc-wrapper .shipping-address-item.selected-item:after {
        background: $green;
    }

    #opc-new-shipping-address {

        .field-tooltip-action {
            position: relative;
            top: 5px;
            right: 10px;
        }

        button {
            @extend .f14;
            font-weight: normal;
            @include botao-padrao($black, $white, false);
        }

        input[type="text"] {
            @include input-padrao($light-gray, $black-80, true);
        }

        select {
            @include select-padrao($light-gray, $black-80);
        }
    }

    .opc-wrapper .form-login, .opc-wrapper .form-shipping-address {
        max-width: 100%;
        padding: 17px;

        .actions-toolbar .remind {
            @extend .f12;
        }

        .label {
            @extend .f12;
            margin: 0 0 5px;
            color: $black;
        }

        button {
            @extend .f14;
            font-weight: normal;
            @include botao-padrao($black, $white, false);
        }

        input {
            @include input-padrao($light-gray, $black-80, true);
        }

        select {
            @include select-padrao($light-gray, $black-80);
        }

        [name="shippingAddress.street.1"] {
            width: 48%;
            float: left;
        }

        [name="shippingAddress.street.2"] {
            width: 48%;
            float: right;
        }

        [name="shippingAddress.street.3"] {
            margin-top: 10px;
        }

        .note {
            @extend .f10;
            color: $black;
        }

        .action-help {
            position: relative;
            top: 5px;
            right: 10px;
        }
    }

    .opc-wrapper .step-content {
        margin: 0;
    }

    .opc-wrapper .methods-shipping {
        max-width: 100%;
        padding: 17px 0;

        button {
            @extend .f14;
            font-weight: normal;
            @include botao-padrao($black, $white, false);
        }
    }

    #checkout {
        max-width: 1024px;
        margin: 0 auto;
    }
    
    .codeseller {
        padding-bottom: 1px;
        margin-bottom: 10px;
    }

    .opc-block-shipping-information .shipping-information-title {
        border: 0;
    }

    .cart-totals, .opc-block-summary .table-totals {
        border-top: 0;
    }

    .discount-code {
        margin-top: -25px;
    }

    #discount-form a {
        @extend .f8;
    }

    #discount-form, .amcard-field-container {
       input[type="text"] {
        @include input-padrao($light-gray, $black-80, false);
        float: none;
       }

       input[type="button"] {
        @extend .f14;
        padding: 15px !important;
        @include botao-padrao($black, $white, false);
       }

       button {
        @extend .f14;
        padding: 15px !important;
        @include botao-padrao($black, $white, false);
       }
    }

    .opc-payment-additional {
        background-color: #FFF;
        border-radius: 8px;
        margin-bottom: 24px !important;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        position: relative;
        padding: 16px;
        top: 5px;

        input[type="text"] {
            @include input-padrao($light-gray, $black-80, true);
        }

        button {
            margin-top: 10px;
            @extend .f14;
            @include botao-padrao($black, $white, false);
        }
    }

    #payment_form_moipcc .fieldset .field.wirecard-input-group .control .label {
        color: rgba(23, 23, 23, 0.2);
    }
      
    .codeseller-box {
        position: relative;
        left: 4px;
        top: 10px;
        p {
            margin-bottom: 2px;
            font-size: 10px !important;
        }
        
        input[type="text"]{
            @include input-padrao($light-gray, $black-80, false);
            width: 100%;
            float: left;
        }
        
        #success-codeseller{
            color: $green;
            display: none;
            text-align: left;
        }
        
        #error-codeseller {
            display: none;
        
        }
        
        #error-remove-codeseller{
            color: $red;
            display: none;
        } 
        
        span {
            @extend .f12;
        }
        
        button {
            @extend .f14;
            @extend .f14;
            width: 75px;
            text-align: center;
            font-weight: normal;
            @include botao-padrao($black, $white, false, 45px);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            line-height: 0;
            float: right;
            top: -46px;
            position: relative;
            left: 75px;
        }
    }

    #checkout-step-shipping_method {
        @extend .f14;
    }

    .swatch-option.text {
        background: transparent;
        color: $black;
        padding: 0;
    }

    .payment-group {
        .radio {
            position: relative;
            top: 5px;
        }
    }

}



@media only screen and (max-width: 1023px) {


    .checkout-payment-method .payment-method-title {
        padding: 0;
        margin-bottom: 12px;
    }

    .checkout-payment-method .checkout-billing-address .billing-address-details {
        padding: 0;
    }

    .payment-method {
        padding: 15px;
    }


    .checkout-index-index .opc-wrapper .methods-shipping {
        padding-left: 16px;
    }

    .opc-estimated-wrapper {
        display: none;
    }

    .opc-payment-additional {
        width: calc(100% - 32px);
        left: 16px;
    }

    .opc-summary-wrapper, .opc-summary-wrapper.custom-slide {

        .action-close {
            display: none;
        }

        visibility: visible;
        bottom: auto;
        left: auto;
        min-width: auto;
        position: relative;
        right: 0;
        top: 0;
        visibility: visible;
        opacity: 1;
        -webkit-transition: visibility 0s .3s,opacity .3s ease;
        transition: visibility 0s .3s,opacity .3s ease;
        left: auto;
        z-index: 900;

        .modal-inner-wrap {
            box-shadow: none;
            background-color: transparent;
            box-sizing: border-box;
            height: auto;
            min-height: 100%;
            height: 100%;
            overflow-y: auto;
            position: static;
            -webkit-transform: translateX(0%);
            transform: translateX(00%);
            -webkit-transition: -webkit-transform .3s ease-in-out;
            transition: transform .3s ease-in-out;
            width: auto;
        }
    }

    .checkout-index-index {
        .opc-wrapper .methods-shipping {
            background: $white !important;
        }

        .opc-wrapper .methods-shipping {
            border: 0;
        }
    
        .opc-wrapper .shipping-address-item {
            width: calc(100% - 0px);
            margin-left: 0px;
        }
        
        #checkout-step-shipping .action-show-popup {
            margin-left: 0;
        }
    }
    
  }