.amrelated-pack-wrapper {
    li {
        list-style: none;
    }
    position: relative;

    .amrelated-title {
        strong {
            @extend .f18;
            text-transform: uppercase;
            text-align: center;
            font-weight: normal;
            display: block;
        }
    }

    .amrelated-background {
        box-shadow: none !important;
        background: transparent !important;
    }

    .amrelated-plus, .amrelated-equal {
        color: $black !important;
    }

    .amrelated-label {
        display: none;
    }

    a, a:hover, a:visited, .alink:visited {
        color: $black;
    }

    .action.primary {
        @include botao-padrao($black, $white, false);
    }

    .amrelated-discount {
        background: $black;
    }

    .amrelated-price-wrapper {
        border: 3px dashed $light-gray !important;
    }

    .amrelated-photo-container {
        border-color: transparent;
    }
}