.cart-totals{

    color: $black-80;
    margin-top: 10px;

    .totals {
      margin-top: 10px;
      
      .mark {
        border-top: 0;
        @extend .f14;
      }
  
      .big {
        font-size: 2rem;
      }
  
      .price {
        @extend .f14;
        float: right;
      }
    }
    .shipping {
      .mark {
        width: 70%;
        display: inline-block;
      }
    }
    .discount-box {
      .title {
        margin-bottom: 0;
        width: 70%;
        display: inline-block;
      }
    }    
  }