.cart-summary {
  @import 'codeseller';
}

#block-summary input[type="text"], #block-summary input[type="tel"]{
@include input-padrao($white, $black-80, false, 75px);
float: left;
}

.cart-summary {
  z-index: 99;
  width: 100%!important;
  /* margin-bottom: 45px; */
  top: initial!important;
  padding: 0 0 60px 0 !important;
  background-color: #fff;

  .checkout-methods-items .action.primary.checkout{
    font-weight: normal;
    @include botao-padrao($black, $white, false);
    float: left;
    width: 300px;
    margin-left: 22px;
  }

  .cart-totals {
    margin: 0 17px;
    padding: 9px 0 27px 0;
  }

  input[type="radio"] {
    @import '../uikit/radio-padrao';
  }


  #co-shipping-method-form {
    .field {
      font-size: 10px;
      margin: 0;
      padding: 0;
      padding-bottom: 8px;
    }
  }

  #discount-coupon-form .label {
      display: none;
  }

  .cart-totals {
    border-top: 0;
  }

  #block-shipping {
    @import '../uikit/radio-padrao';

    input[type="radio"]{
      position: relative;
      top: -4px;
    }
    
    z-index: 99;
    position: relative;
    #shipping-zip-form {
      
      .field {
        margin: 0 0 15px;
      }

      button {
        @include botao-padrao($black, $white, false);
        font-size: 14px;
        float: right;
        height: 45px;
        font-weight: 0;
        margin-top: -15px;
      }
      input {
        width: calc(100% - 83px);
      }
      a {
        position: relative;
        top: -8px;
        @extend .f10;
        color: $black-80;
        text-decoration: underline;
      }
    }
    .label {
      color: $black-80;
      @extend .f14;
    }
    .item-options {
      margin: 0;
    }
    .item-title span {
      @extend .f12;
      color: $black-80;
    }
    padding-bottom: 12px;
    a {
      @extend .f12;
    }
  }

  .checkout-methods-items {
    padding-top: 40px !important;
    position: fixed;
    bottom: 0px;
    width: 90%;
    padding-bottom: 20px !important;
    background-color: $white;
    z-index: 99;
  }

  #discount-coupon-form {
    margin-bottom: 10px;
  }

  #block-discount {
    padding-bottom: 8px;
  }

  #shipping-zip-form input{
    width: 100%;
    margin-bottom: 10px;
  }

  #block-shipping, #block-discount {
    background-color: $light-gray;
    padding: 0 15px;
    border-radius: 3px;
    margin: 10px 17px 0;
    .title {
      border-top: 0;
      color: $black-80;
      strong {
        @extend .f12;
        font-weight: normal;
      }
    }
    .content {
      padding-bottom: 1px;
    }
    .item-title {
      font-weight: normal;
    }
    .fieldset {
      margin: 0;
    }
    .field[name="shippingAddress.postcode"]{
      .label{
        display: none;
      }
    }

    .field[name="shippingAddress.country_id"], .field[name="shippingAddress.region_id"]{
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .cart-summary .checkout-methods-items .action.primary.checkout {
    width: 250px !important;
    margin-left: 5% !important;
  }

  .minicart-wrapper .copy-cart {
    right: 54px !important;
  }
}

@media only screen and (max-width: 360px) {
  .copy-cart {
    width: 200px;
    left: 75px;
  }
  .cart-summary .checkout-methods-items .action.primary.checkout {
    margin-left: 2% !important;
  }
}