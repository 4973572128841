.amrelated-grid-wrapper {
    margin-top: -20px;
    .block-title {
        margin: 0 0 10px;
        strong {
            @extend .f18;
            text-transform: uppercase;
            font-weight: normal;
            text-align: center;
            display: block;
            margin: 0 auto;
        }
    }

    .amrelated-name {
        text-transform: uppercase;
    }

    .widget-product-grid {
        display: inline-table;
    }

    .products-grid .product-item-info:hover, .products-grid .product-item-info.active {
        border: 1px solid transparent;
        box-shadow: none;
    }

    .product-item-actions {
        display: none;
    }

    .product-item-name a {
        @extend .f12;
        text-transform: uppercase;
        text-align: center;
        display: block;
    }

    .price-container span {
        @extend .f12;
        text-align: center;
        display: block;
        margin: 0 auto;
    }
            
    .price-box {
        text-align: center;
        .old-price {
            display: inline-block;
            position: relative;
            top: -2px;
            padding: 0;
            margin: 2px auto;
            margin-left: 5px;
        }
        .normal-price{
            display: block;
            padding: 0;
            margin: 0 auto;
        }
        .price {
            @extend .f14;
        }
    }
    

    @media screen and (max-width:1023px){

        .amrelated-pack-item .price-box {
            margin-bottom: 30px;
        }

        .block-title {
            text-align: center;
            strong {
                text-align: center;
            }
        }
    }
}