.cart-container {
    .form-cart {
        width: 100% !important;
        padding-right: 0 !important;
    }

    #form-validate {
        .actions {
            button{
                font-weight: normal;
                margin-top: -3px;
                @include botao-padrao($black, $white, false, 43px);
                line-height: 0;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            .checkout{
                margin: 10px auto;
                display: inline-block;
                
                @media (max-width: 768px) {
                    width: 100%;
                    padding: 10px 0px;
                }

                font-weight: normal;
                @include botao-padrao($black, $white, false);
            }
        }
    }
}


.cart-empty {
    text-align: center;
}